.ccard {
  position: relative;
  top: 0;
  left: 0;
  height: 350px;
  border-radius: 5%;
  flex-direction: column;
  width: 300px;
  border-color: #24524c;
  box-shadow: 4px 7px 10px #24524c;
  transition: transform 0.3s ease-in-out;
  overflow: hidden; 
}

.ccardoverlay {
    position: absolute; /* Position overlay element absolutely */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5)); /* Gradient */
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5)); /* Gradient */
  opacity: 0; /* Initially hide the overlay */
  transition: opacity 0.3s ease-in-out; /* Smooth transition on hover */
  z-index: 0; /* Optional to ensure overlay is behind card content */
}

.ccardoverlay-text {
    position: absolute; /* Position text element absolutely within the overlay */
    color: white; /* Text color */
    font-size: 20px; /* Adjust font size as needed */
    text-align: left; /* Align text to the left */
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
    /* Adjust these values to position the text at the bottom left */
    left: 10px; 
    bottom: 10px;
    
}

.cardimg {
    object-fit: cover;
  height: 350px;
  border-radius: 5%;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.ccard:hover .ccardoverlay-text {
  opacity: 1; /* Show text on hover */
  transform: translateY(0); /* Move text back to its original position */
}

/* Optional: Animation Keyframes (adjust for bottom left entrance) */
@keyframes animateTextFromBottomLeft {
    from { transform: translateX(-50%) translateY(50%); opacity: 0; }
    to { transform: translateX(0) translateY(0); opacity: 1; }
}

.ccard:hover .ccardoverlay-text  {
    animation: animateTextFromBottomLeft 0.3s ease-in-out; /* Use the animation */
}


.ccard:hover .ccardoverlay {
    opacity: 1;
    box-shadow: 7px 10px 14px #24524c;
}

.ccard:hover .cardimg {
    transform: scale(1.15);
}